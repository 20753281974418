import React, {useEffect} from "react";
import { Popup } from "@partssourceinc/react-ui-core/components";
import RadioButton from "@partssourceinc/react-ui-core/components/RadioButton";

export const RegistrationAddressDialog = ({
    validating,
    suggestedAddress,
    originalChecked,
    originalAddressLabel,
    suggestedAddressLabel,
    handleOnSave,
    handleOnChange,
    handleOnClose,
}) => {
    useEffect(() => {
        dataLayer.push({'event': 'registrationStep','stepName': 'RegistrationAddressDialog'});
    }, []);  

    return (
        <Popup
            confirmText="Use This Address"
            cancelText="Cancel and Edit"
            show={true}
            onConfirm={handleOnSave}
            onCancel={handleOnClose}
            className="address-dialog"
        >
            <div className="address-validation-form">
                <h1>Verify Your Address</h1>
                {!suggestedAddress && (
                    <div className="not-verified">
            This address could not be verified.
                    </div>
                )}
                <div className="section-title">Your Entry</div>
                {!validating && (
                    <React.Fragment>
                        <RadioButton
                            id="original-address"
                            checked={originalChecked}
                            label={originalAddressLabel}
                            value="1"
                            onChange={handleOnChange}
                            name="original-address"
                        />

                        {suggestedAddressLabel && (
                            <React.Fragment>
                                <div className="section-title">Suggested Address</div>
                                <RadioButton
                                    id="suggested-address"
                                    checked={!originalChecked}
                                    label={suggestedAddressLabel}
                                    value="2"
                                    onChange={handleOnChange}
                                    name="suggested-address"
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        </Popup>
    );
}
